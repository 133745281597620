import {useContext, useEffect, useState} from 'react';
import CallTrackingNumber from '../CallTrackingNumber';
import AcfTooltip from '../Tooltips/AcfTooltip';
import AppContext from '../AppContext';

const CallrailLink = ({style, children}) => {
    const {callTrackingNumber} = useContext(AppContext);

    useEffect(() => {
        if (typeof window !== 'undefined' && window.CallTrk) {
            window.CallTrk.swap();
        }
    }, [callTrackingNumber]);

    return (
        <div style={{...style}} className="container">
            <div className="callrail-link">
                <a
                    href={callTrackingNumber?.href}
                    title={`Call ${callTrackingNumber?.href}`}
                    style={{...style}}>
                    {children ? <>{children}</> : <>{callTrackingNumber?.display}</>}
                </a>
                <div className="tooltip">
                    <AcfTooltip type="phone" hideText />
                </div>
            </div>

            <style jsx>
                {`
                    .container {
                        display: inline-block;
                    }
                    a {
                        min-width: 100px;
                    }
                    .callrail-link {
                        display: flex;
                        align-items: center;
                    }
                    .tooltip {
                        margin-top: 2px;
                    }
                `}
            </style>
        </div>
    );
};
export default CallrailLink;
